import React from 'react';
import {FloatingPortal} from '@floating-ui/react';
import {Absolute} from '../../layout';
import {DefaultTheme} from 'styled-components';

export default function Portal({
    children,
    zIndex = 'Portal' as const
}: {
    children: React.ReactNode;
    zIndex?: keyof DefaultTheme['zIndices'];
}) {
    return (
        <FloatingPortal>
            <Absolute top={0} left={0} zIndex={zIndex}>
                {children}
            </Absolute>
        </FloatingPortal>
    );
}
