import {useLayoutEffect} from 'react';

export default function useLockBodyScroll() {
    useLayoutEffect(() => {
        const originalStyle = window.getComputedStyle(document.body).overflow;
        // dont lock the scroll of the page if it has been locked previously (eg by another modal)
        if (originalStyle === 'hidden') return;

        document.body.style.overflow = 'hidden';
        return () => {
            document.body.style.overflow = originalStyle;
        };
    }, []);
}
