import {StyledProps, Absolute} from '../../layout';
import React, {InputHTMLAttributes, useId, useMemo, useState} from 'react';
import Styled from 'styled-components';
import debounce from '../util/debounce';
import {IconSearch, IconCross} from '../../icon';
import Spinner from './Spinner';
import Clickable from './Clickable';
import useStyleFeatureFlags from '../useStyleFeatureFlags';

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
    value?: any;
    isError?: boolean;
    isIcon?: boolean;
    /** Set row number of a textarea, if `as="textarea"` is also passed in */
    rows?: string;
    style?: Record<string, any>;
    /** Debounce time. Defaults to 350ms */
    debounce?: number;
    onDebounce?: (next: any) => void;
    /**
    To debounce a callback you need to memoise it between renders.
    If the onDebounce callback is changing due to props you might find that it's become stale
    */
    debounceDeps?: React.DependencyList;
}

const {squareRadius} = useStyleFeatureFlags();

const IconWrapper = Styled.div`
    position: absolute;
    top: ${squareRadius ? '9px' : '8px'};
    left: .75rem;
    color: ${(p) => p.theme.colors.background2};
`;

const InputWrapper = Styled.div`
    position: relative;

    &:focus-within {   
        ${IconWrapper} svg {
            color: ${(p) => p.theme.colors.brand};
        }
    }            
`;

const StyledInput = Styled.input<
    StyledProps & InputProps & {as: 'textarea' | 'input'; hasClearButton?: boolean}
>`${(p) => {
    const {colors, fontWeights, borderRadiuses} = p.theme;
    return `
        -webkit-appearance: none;
        border-radius: ${squareRadius ? borderRadiuses.round : '1rem'};
        border: none;
        box-shadow: inset 0 0 0 ${squareRadius ? 1 : 2}px ${
        squareRadius ? colors.buttonOutline : colors.outline
    };
        box-sizing: border-box;
        color: ${colors.foreground};
        background-color: ${colors.background};
        cursor: text;
        display: block;
        font-family: inherit;
        font-size: inherit;
        font-weight: ${fontWeights.regular};
        min-height: 2rem;
        padding: .25rem .5rem;
        line-height: 1.5;
        width: 100%;
        height: ${squareRadius ? '2.5rem' : p.height};

        &:-ms-input-placeholder {
            color: ${squareRadius ? colors.buttonOutline : colors.muted};
        }

        &::placeholder {
            color: ${squareRadius ? colors.buttonOutline : colors.muted};
        }

        &:-webkit-autofill {
            -webkit-text-fill-color: ${colors.foreground} !important;
        }


        &:focus {
            box-shadow: inset 0 0 0 ${squareRadius ? 1 : 2}px ${colors.brand};
            outline: none;

        }

        ${p.isError ? `border: 1px solid ${colors.red};` : ''}
        ${p.isIcon ? `padding-left:${squareRadius ? '2.5rem' : '2.25rem'};` : ''}
        ${p.hasClearButton ? `padding-right: 2.25rem` : ''}`;
}}`;

type Props = Omit<InputProps, 'onChange'> & {
    as?: 'textarea' | 'input';
    icon?: React.ReactNode;
    onChange?: (value: string, event?: React.ChangeEvent<HTMLInputElement>) => void;
    loading?: boolean;
    clearable?: boolean;
};

export default React.forwardRef(function Input(
    props: Props,
    ref: React.MutableRefObject<HTMLInputElement>
) {
    const {
        value: valueFromProps,
        onChange: onChangeFromProps,
        onDebounce: onDebounceFromProps,
        debounce: debounceFromProps,
        loading,
        ...inputProps
    } = props;

    const [value, setValue] = useState(valueFromProps ?? '');

    const onDebounce = useMemo(() => {
        if (!onDebounceFromProps) return null;
        let debounceTime = debounceFromProps ?? 350;
        if (process.env.NODE_ENV === 'test') debounceTime = 0;
        return debounce(onDebounceFromProps, debounceTime);
    }, props.debounceDeps || []);

    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const next = e.target.value;
        onChangeFromProps?.(next, e);
        onDebounce?.(next);
        setValue(next);
    };

    const uniqueId = useId();
    const id = inputProps.id ?? uniqueId;

    const hasIcon = Boolean(props.icon || props.type === 'search' || loading);
    const showClearButton = props.clearable && String(value).trim().length > 0;
    return (
        <InputWrapper
            style={{
                display: props.type === 'hidden' ? 'none' : undefined,
                width: props.width,
                height: props.height
            }}
        >
            {hasIcon && (
                <IconWrapper as="label" htmlFor={id}>
                    {loading ? (
                        <Spinner />
                    ) : (
                        props.icon || (
                            <div data-testid="appcues-input-search-icon">
                                <IconSearch
                                    size={squareRadius ? '1.25rem' : undefined}
                                    color="buttonOutline"
                                />
                            </div>
                        )
                    )}
                </IconWrapper>
            )}
            <StyledInput
                {...inputProps}
                id={id}
                ref={ref}
                as={props.as || 'input'}
                rows={props.rows}
                isError={!!props.isError}
                isIcon={hasIcon}
                hasClearButton={showClearButton}
                onChange={onChange}
                value={value}
            />
            {showClearButton && (
                <Absolute
                    top={squareRadius ? '11px' : '8px'}
                    bottom={1}
                    cursor="pointer"
                    right=".75rem"
                    backgroundColor="background"
                >
                    <Clickable
                        onClick={() => {
                            onChangeFromProps?.('');
                            onDebounce?.('');
                            setValue('');
                        }}
                        aria-label="Clear text"
                    >
                        <IconCross />
                    </Clickable>
                </Absolute>
            )}
        </InputWrapper>
    );
});
