import {Box} from '../../../layout';
import styled from 'styled-components';
import {bool} from '../../core/themes/themeGetters';
import useStyleFeatureFlags from '../../useStyleFeatureFlags';

const {squareRadius} = useStyleFeatureFlags();

export const Layout = styled(Box)<{isOpen: boolean}>`
    position: relative;
    min-width: 0;
    ${bool('isOpen')((props) => {
        return `
            z-index: ${props.theme.zIndices.Overlay};
            background-color: ${props.theme.colors.background};
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            height: 100vh;

            @media (max-width: ${props.theme.breakpoints.mdMaxWidth}) {
                overflow-y: scroll;
            }

            @media (min-width: ${props.theme.breakpoints.md}) {
                background-color: unset;
                position: relative;
                top: unset;
                left: unset;
                width: unset;
                height: unset;
            }
        `;
    })}
`;

export const Control = styled.div<{isOpen: boolean; disabled?: boolean}>`
    position: relative;
    cursor: ${(_) => (_.disabled ? 'not-allowed' : 'pointer')};
    ${bool('isOpen')((props) => {
        return `
            display: flex;
            justify-content: space-between;
            margin: 1rem;

            @media (min-width: ${props.theme.breakpoints.md}) {
                display: block;
                margin: 0;
            }
        `;
    })}
`;
Control.defaultProps = {
    tabIndex: 0
};

export const Menu = styled(Box)<{
    isOpen: boolean;
    direction: 'top' | 'bottom';
    align: 'left' | 'right';
}>`
    z-index: 2;
    background-color: ${(p) => p.theme.colors.background};
    outline: none;

    @media (max-width: ${(_) => _.theme.breakpoints.mdMaxWidth}) {
        width: 100vw !important;
        left: 0 !important;
        right: 0 !important;
        margin: 0 !important;
    }

    &:focus {
        border-color: ${(_) => _.theme.colors.brand};
    }

    @media (min-width: ${(_) => _.theme.breakpoints.md}) {
        overflow: auto;
        position: absolute;
        z-index: ${(p) => p.theme.zIndices.Dropdown};
        ${(p) => (p.direction === 'bottom' ? 'top: 120%;' : '')}
        height: auto;
        right: ${(p) => (p.align === 'right' ? 0 : 'auto')};
        max-height: ${(p) => p.maxHeight ?? '50vh'};
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
        border-radius: 8px;
        border: ${squareRadius ? 1 : 2}px solid ${(_) => _.theme.colors.outline};
    }
    ${(props) => !props.isOpen && 'display: none;'}
`;

export const DropdownList = styled.ul`
    ${(p) => `
        border-top: ${squareRadius ? 1 : 2}px solid ${p.theme.colors.outline};
        background-color: ${p.theme.colors.background};
        color: ${p.theme.colors.foreground};
        padding: .5rem 0;
        &:first-child {
            border-top: none;
        }
    `}
`;
