import useStyleFeatureFlags from '../useStyleFeatureFlags';
import styled from 'styled-components';
import {bool} from '../core/themes/themeGetters';

type Props = {
    hasValue?: boolean;
    disabled?: boolean;
    dashedBorder?: boolean;
    isError?: boolean;
    transparent?: boolean;
    small?: boolean;
    hideCaret?: boolean;
};

const {squareRadius} = useStyleFeatureFlags();
export default styled.button<Props>`
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    border: ${squareRadius ? 1 : 2}px solid;
    border-color: ${(p) => (squareRadius ? p.theme.colors.buttonOutline : p.theme.colors.outline)};
    background-color: ${(props) => props.theme.colors.background};
    border-radius: ${(p) => (squareRadius ? p.theme.borderRadiuses.round : '2rem')};
    padding: 0.125rem 0.75rem;
    height: ${squareRadius ? '2.5rem' : '2rem'};
    color: inherit;
    white-space: nowrap;
    cursor: pointer;

    ${bool('small')(() => {
        return `
            font-size: .85rem;
            padding-left: .75rem;
            padding-right: .75rem;
            height: 1.85rem;
        `;
    })}

    ${bool('disabled')((p) => {
        return `
            color: ${p.theme.colors.muted};
            border-color: ${p.theme.colors.outlineDisabled};
            background-color: ${p.theme.colors.background1};
            cursor: not-allowed;
        `;
    })}

    ${bool('transparent')((p) => {
        return `
            padding-left: .25rem;
            padding-right: .25rem;
            border-color: transparent;
            background-color: transparent;
            width: auto;
        `;
    })}

    ${bool('dashedBorder')((p) => {
        return `border-style: dashed;`;
    })}

    ${bool('isError')(
        (p) => `
            border-color: ${p.theme.colors.red};
        `
    )}

    &:hover {
        outline: none;
        border-color: ${(p) => p.theme.colors.brand};
        color: ${(p) => p.theme.colors.brand};
    }

    &::after {
        display: inline-block;
        content: '▾';
        margin-left: 0.25rem;
    }

    ${bool('hideCaret')(() => {
        return '&::after {content: none;}';
    })}
`;
