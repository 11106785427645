import {LightTheme as colors} from 'bigdatr-theme';
import hash from '../../util/hash';

const colorList = [
    colors.green,
    colors.cyan,
    colors.blue,
    colors.purple700,
    colors.pink,
    colors.red,
    colors.orange,
    colors.yellow
];

export type IndustryInput = {id?: string; name: string; hasAccess?: boolean | null};

export default class Industry {
    private data: IndustryInput;
    type = 'industry' as const;

    constructor(input: IndustryInput) {
        this.data = input;
    }

    static to(id: string) {
        return `/industry/${id}/creative`;
    }

    get color() {
        return colorList[Number(hash(this.label)[0]) % colorList.length];
    }

    get id() {
        return this.data.id || this.data.name;
    }

    get label() {
        return this.data.name;
    }

    get to() {
        return Industry.to(this.id);
    }

    get hasAccess() {
        return this.data.hasAccess ?? null;
    }

    get hasProducts() {
        return [
            'Automotive',
            'Automotive Dealership',
            'Property Development',
            'Communications'
        ].includes(this.label);
    }

    get trackingType() {
        return {type: 'industry', name: this.label};
    }

    get onboardDate() {
        switch (this.label) {
            case 'Travel and Accommodation':
            case 'Airlines':
            case 'Tourism':
            case 'Travel Agents':
            case 'Department Store':
            case 'Education':
                return '2019-03-01';

            case 'Technology':
                return '2019-08-01';

            case 'Communications':
                return '2020-06-01';

            default:
                return '2017-01-01';
        }
    }
}
