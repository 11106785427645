import type {DefaultTheme} from 'styled-components';
import {LightTheme} from 'bigdatr-theme';

export type ThemeType = DefaultTheme;

export type ThemeConfig = {
    fontStack: 'bigdatr' | 'system' | 'monospace';
    spacing: 'normal' | 'cosy' | 'tight';
};

export default function baseTheme(config: ThemeConfig): DefaultTheme {
    const normalSpacing = [0, 4, 8, 16, 32, 64, 128, 256, 512];
    const spacing = {
        normal: normalSpacing,
        cosy: normalSpacing.map((ii) => ii - 2),
        tight: normalSpacing.map((ii) => ii / 2)
    };

    const sizes: DefaultTheme['sizes'] = {
        navigationOffset: 'calc(100vh - 4rem)'
    };

    const borderRadiuses: DefaultTheme['borderRadiuses'] = {
        round: '0.625rem'
    };

    const borders: DefaultTheme['borders'] = {
        outline: '2px solid #ccc',
        outlineAlt: `2px solid ${LightTheme.outlineAlt}`,
        tableThin: '1px solid #e6e6e6',
        table: '2px solid #e6e6e6',
        cutout: '2px dashed #ccc',
        error: `2px solid ${LightTheme.red}`
    };

    // Named aliases so we dont have to use array order in layout components
    const breakpoints: DefaultTheme['breakpoints'] = {
        sm: '576px',
        smMaxWidth: '575px',
        md: '768px',
        mdMaxWidth: '767px',
        lg: '992px',
        lgMaxWidth: '991px',
        xl: '1200px',
        xlMaxWidth: '1199px',
        xxl: '1440px',
        xxlMaxWidth: '1439px'
    };

    //
    // Fonts
    const fontStacks = {
        bigdatr: 'Phantom, sans-serif',
        system: 'system-ui, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
        monospace: 'Menlo, monospace'
    };
    const fonts: DefaultTheme['fonts'] = {
        heading: fontStacks[config.fontStack],
        copy: fontStacks[config.fontStack],
        monospace: 'Menlo, monospace'
    };

    const fontSizes: DefaultTheme['fontSizes'] =
        config.fontStack === 'monospace'
            ? [10, 12, 14, 14, 16, 18, 24]
            : [10, 12, 16, 24, 32, 40, 48];

    const fontStackWeights = {
        bigdatr: [400, 800, 900],
        system: [400, 500, 600],
        monospace: [400, 500, 700]
    };
    const [regular, bold, heavy] = fontStackWeights[config.fontStack];

    const heading = (size: number, weight: number, lineHeight = 1) => ({
        lineHeight,
        fontFamily: fonts.heading,
        fontWeight: weight,
        fontSize: fontSizes[size]
    });

    const textStyles: DefaultTheme['textStyles'] = {
        heading0: heading(6, heavy),
        heading1: heading(5, heavy),
        heading2: heading(4, heavy),
        heading3: heading(3, bold),
        heading4: heading(3, regular, 1.25),
        code: {
            fontFamily: fonts.monospace,
            fontSize: '.75rem', // Monospaced fonts shouldn't be scaled
            fontWeight: 100
        },
        copy: {
            fontWeight: regular,
            fontSize: fontSizes[2],
            fontFamily: fonts.copy
        },
        muted: {
            color: LightTheme.muted
        },
        strong: {
            fontWeight: bold
        },
        smallLabel: {
            fontWeight: bold,
            fontSize: fontSizes[1]
        },
        emphasis: {
            fontStyle: 'italic'
        },
        href: {
            color: LightTheme.link,
            cursor: 'pointer'
        },
        clickable: {
            cursor: 'pointer'
        },
        capitalize: {
            textTransform: 'capitalize'
        },
        ellipsis: {
            maxWidth: '100%',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            display: 'block'
        },
        number: {
            fontVariantNumeric: 'tabular-nums'
        },
        disclaimer: {
            display: 'block',
            fontSize: '0.8em',
            color: LightTheme.muted,
            fontStyle: 'italic'
        }
    };

    const lineHeights: DefaultTheme['lineHeights'] = [1, 1.2, 1.6, 1.8];

    const zIndices: DefaultTheme['zIndices'] = {
        Tooltip: 101,
        Dropdown: 102,
        Paper: 103,
        Navigation: 104,
        Toast: 106,
        Banner: 107,
        FilterOverlay: 108,
        // @NOTE: The #hubspot-messages-iframe-container is given a
        // zindex of 109 to sit above the FilterOverlay, but below the classic Overlay.
        // This is done because we have a CTA while filters are open.
        // The z-index for the hubspot chat is hardcoded in BaseStyles.css
        Portal: Number.MAX_SAFE_INTEGER - 3,
        Overlay: Number.MAX_SAFE_INTEGER - 2,
        Filters: Number.MAX_SAFE_INTEGER - 1,
        TooltipTable: Number.MAX_SAFE_INTEGER
    };

    const boxShadows: DefaultTheme['boxShadows'] = {
        popup: '0px 0px 4px 0px #00000040'
    };

    const transition: DefaultTheme['transition'] = {
        quick: '0.175s ease-in-out'
    };

    return {
        isDark: false,
        name: 'Light',
        transition,
        borderRadiuses,
        borders,
        breakpoints,
        colors: LightTheme,
        fontSizes,
        fontWeights: {regular, bold, heavy},
        fonts,
        lineHeights,
        sizes,
        space: spacing[config.spacing],
        textStyles,
        zIndices,
        boxShadows
    };
}
