import React, {useState} from 'react';
import {Fixed} from '../../layout';
import Portal from '../affordance/Portal';
import useLockBodyScroll from '../util/useLockBodyScroll';
import {useFloating, useInteractions, useDismiss} from '@floating-ui/react';

export default function FullscreenModal({
    children,
    onRequestClose
}: {
    children: React.ReactNode;
    onRequestClose?: () => void;
}) {
    useLockBodyScroll();

    const [open, setOpen] = useState(true);
    const {refs, context} = useFloating({
        open,
        onOpenChange: (next) => {
            setOpen(next);
            if (!next) onRequestClose?.();
        }
    });

    const dismiss = useDismiss(context);

    const {getFloatingProps} = useInteractions([dismiss]);

    return (
        <Portal>
            <Fixed
                ref={refs.setReference}
                {...getFloatingProps()}
                inset="0"
                width="100vw"
                height="100vh"
                overflow="auto"
                backgroundColor="background"
                data-testid="fullscreen-modal"
            >
                {children}
            </Fixed>
        </Portal>
    );
}
