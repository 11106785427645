import {formatDate, formatDateRange, formatDurationAsDays} from '../util/textFormat';
import Media from './Media';
import {CreativeStatusType} from '../creativeStatus/data/CreativeStatus';
import AdType, {AdTypeInput} from '../adType/data/AdType';
import Brand, {BrandInput} from '../brand/data/Brand';
import Category, {CategoryInput} from '../category/data/Category';
import Industry, {IndustryInput} from '../industry/data/Industry';
import MediaOwner, {MediaOwnerInput} from '../mediaOwner/data/MediaOwner';
import MediaType, {MediaTypeInput} from '../mediaType/data/MediaType';
import Product, {ProductInput} from '../product/data/Product';
import Publication, {PublicationInput} from '../publication/data/Publication';
import Publisher, {PublisherInput} from '../publisher/data/Publisher';
import Region, {RegionInput} from '../region/data/Region';

type CreativeInput = {
    id: string;
    creative: string;
    firstAppeared?: string | null;
    lastAppeared?: string | null;
    status?: CreativeStatusType;
    adType?: AdType | AdTypeInput | null;
    categoryList?: Category[] | CategoryInput[];
    mediaTypeList?: MediaType[] | MediaTypeInput[];
    publicationList?: Publication[] | PublicationInput[];
    regionList?: Region[] | RegionInput[];
    productList?: Product[] | ProductInput[];
    brandList?: Brand[] | BrandInput[];
    industryList?: Industry[] | IndustryInput[];
    publisherList?: Publisher[] | PublisherInput[];
    mediaOwnerList?: MediaOwner[] | MediaOwnerInput[];
    country?: string | null;
    placementTypes?: string[] | null;
    advertiserDomain?: string | null;
    advertiserName?: string | null;
};

export default class Creative extends Media<Creative> {
    private data: CreativeInput;

    constructor(input: CreativeInput) {
        const {id, creative = 'MISSING_CREATIVE'} = input;
        super({id, creative});
        this.data = input;
    }

    static downloadUrl(list: Array<string>): string {
        const endpoint = process.env.BIGDATR_ENTITY_API_ENDPOINT;
        return `${endpoint}/creativeDownload?creativeList=${list.join(',')}`;
    }

    //
    // Entities
    get adType() {
        if (!this.data.adType) return;
        return ensure(AdType)(this.data.adType);
    }

    get brandList() {
        return (this.data.brandList || []).map((bb) => ensure(Brand)(bb));
    }

    get categoryList() {
        return (this.data.categoryList || []).map((bb) => ensure(Category)(bb));
    }

    get industryList() {
        return (this.data.industryList || []).map((bb) => ensure(Industry)(bb));
    }

    get mediaOwnerList() {
        return (this.data.mediaOwnerList || []).map((bb) => ensure(MediaOwner)(bb));
    }

    get mediaTypeList() {
        return (this.data.mediaTypeList || []).map((bb) => ensure(MediaType)(bb));
    }

    get productList() {
        return (this.data.productList || []).map((bb) => ensure(Product)(bb));
    }

    get publicationList() {
        return (this.data.publicationList || []).map((bb) => ensure(Publication)(bb));
    }

    get publisherList() {
        return (this.data.publisherList || []).map((bb) => ensure(Publisher)(bb));
    }

    get regionList() {
        return (this.data.regionList || []).map((bb) => ensure(Region)(bb));
    }

    get placementTypesList() {
        return (this.data.placementTypes || []).map((bb) => bb.toString());
    }

    //
    // Other attributes

    get firstAppeared(): Date | undefined {
        return this.data.firstAppeared ? new Date(this.data.firstAppeared) : undefined;
    }
    get lastAppeared(): Date | undefined {
        return this.data.lastAppeared ? new Date(this.data.lastAppeared) : undefined;
    }

    get status() {
        return this.data.status ?? 'inactive';
    }

    get statusColor(): string {
        if (this.status === 'new') return 'green';
        if (this.status === 'active') return 'blue';
        return 'muted';
    }

    get schedule() {
        const format = formatDate('MMM d yyyy');
        if (!this.firstAppeared && this.lastAppeared) {
            return `Unknown Date - ${format(this.lastAppeared)}`;
        }
        if (!this.lastAppeared && this.firstAppeared) {
            return `${format(this.firstAppeared)} - Unknown Date`;
        }
        if (this.firstAppeared && this.lastAppeared) {
            const formattedRange = formatDateRange(this.firstAppeared, this.lastAppeared, false);
            const days = formatDurationAsDays(
                this.firstAppeared,
                new Date(Math.min(+new Date(), +this.lastAppeared))
            );
            return `${formattedRange} (${days.replace(' ', '\xa0')})`;
        }
        return 'Unknown Schedule';
    }

    get advertiserName() {
        return this.data.advertiserName;
    }

    get advertiserDomain() {
        return this.data.advertiserDomain;
    }

    /** returns either a comma separated list of brand names, but will fallback to
     * adclarity's advertiserName attribute for non australian markets */
    get advertisers() {
        if (this.brandList.length > 0) return this.brandList.map((b) => b.label).join(', ');
        return this.advertiserName;
    }

    //
    // Thumnbails
    get croppedThumbnail(): string {
        return this.mediaTypeList.some((mm) => mm.canonicalName === 'Digital Social') // We don't want to tile social creatives
            ? this.thumbnailUrl('w512c16_9t1', 'jpg')
            : this.thumbnailUrl('w512c16_9', 'jpg');
    }

    get croppedThumbnailWebp(): string {
        return this.mediaTypeList.some((mm) => mm.canonicalName === 'Digital Social') // We don't want to tile social creatives
            ? this.thumbnailUrl('w512c16_9t1', 'webp')
            : this.thumbnailUrl('w512c16_9', 'webp');
    }

    //
    // Other helpers
    get to() {
        return `/creative/${this.id}`;
    }

    get publicLink() {
        return `${process.env.BIGDATR_CLIENT_MAIN_HOST}creative/${this.id}`;
    }

    get dataEntryLink() {
        return `${process.env.BIGDATR_CLIENT_DATAENTRY_HOST}creative-tagger/${this.id}`;
    }

    get isRadio() {
        if (this.mediaTypeList.length) {
            // @todo this is here because the activity inbox creates a faux creative out of a campaign
            // Those campagins can have multiple media types and we only want to say it's radio if all of them are.
            return this.mediaTypeList?.every((m) =>
                m.canonicalName.toLowerCase().includes('radio')
            );
        } else {
            return this.creative.endsWith('.mp3');
        }
    }

    get country() {
        return this.data.country ?? 'Australia';
    }

    toTracking() {
        const brands = this.brandList.map((b) => b.label);
        return {
            creativeId: this.id,
            ...(brands.length > 0 && {brands}),
            advertiserName: this.advertiserName
        };
    }
}

function ensure<C, A>(Clazz: {new (input: A): C}) {
    return function (input: C | A) {
        if (input instanceof Clazz) return input;
        return new Clazz(input as A);
    };
}
